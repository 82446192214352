<template>
  <div class="main-container">
    <Header />

    <Breadcrumb :items="items" />

    <ServiceDetailsWrapper
      v-if="schoolSubjects != null"
      :schoolDetails="schoolDetails"
      :schoolSubjects="schoolSubjects"
    />

    <BrandCarousel addClass="grey-bg" />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import data from "../data/service.json";
import Header from "@/components/Header";
import Breadcrumb from "../components/Breadcrumb";
import ServiceDetailsWrapper from "../components/sections/ServiceDetailsWrapper";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

import { schoolService } from "../services/school";

export default {
  components: {
    Header,
    Breadcrumb,
    ServiceDetailsWrapper,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
  },
  data() {
    return {
      data,
      schoolId: null,
      schoolDetails: null,
      schoolSubjects: null,
      items: {
        title_chs: "",
        title_eng: "",
        sub_title: "",
        img_url: "",
        tags: [],
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.schoolId = this.$route.query.id;
      //console.log(this.schoolId);
      this.getSchData();
    },
    async getSchData() {
      this.schoolDetails = await schoolService.getSchoolData(this.schoolId);
      let returns = await schoolService.getSchoolSubjects(this.schoolId);
      //以下为数据预处理
      //排除内容长度为0的课程等级
      let levels = [];
      let subjects = [];
      let count = 0;
      for (let i = 0; i < returns.length; i++) {
        if (returns[i].subjects.length != 0) {
          levels.push({ value: count, text: returns[i].type });
          count = count + 1;
          subjects.push(returns[i]);
        }
      }
      if (subjects.length != 0) {
      subjects[0].validTypes = levels;
      }
      this.schoolSubjects = subjects;
      //设置传给breadscrumb的内容
      this.items.title_chs = this.schoolDetails.name_chs;
      this.items.title_eng = this.schoolDetails.name_eng;
      this.items.sub_title = this.schoolDetails.sub_title;
      this.items.img_url = this.schoolDetails.school_env_images[0];
      //转换地图坐标格式为float
      this.schoolDetails.lat = parseFloat(this.schoolDetails.lat);
      this.schoolDetails.lng = parseFloat(this.schoolDetails.lng);
      //分离院校介绍的段落和标题
      let introList = this.schoolDetails.introduction.split("## ");
      let allIntro = [];
      console.log(introList);
      for (let i = 0; i < introList.length; i++) {
        allIntro.push({
          title: introList[i].substr(0, introList[i].indexOf("\n")),
          content: introList[i].substr(introList[i].indexOf("\n") + 1),
        });
        //console.log(allIntro);
      }

      this.schoolDetails.introduction = allIntro;
      //console.log(this.schoolDetails);
      //console.log(this.schoolSubjects);
    },
  },
  metaInfo: {
    title: "新加坡留学网 - 课程详情",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
};
</script>

