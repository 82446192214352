<template>
  <div class="page-wrapper section-space--inner--120">
    <!--Projects section start-->
    <div class="project-section">
      <div class="container">
        <div class="row">
          <div class="col-12 section-space--bottom--40">
            <!-- <div class="project-image"><img :src="data.projectDetails.projectDetailsImage" class="img-fluid" alt="image"></div> -->
            <ServiceDetailsMain :schoolDetails="schoolDetails" />
          </div>

          <div class="col-lg-4 col-12 section-space--bottom--30">
            <div class="project-information">
              <!-- <h3>123</h3> -->
              <ul>
                <li>
                  <img
                    :src="schoolDetails.school_logo"
                    class="img-fluid"
                    alt="image"
                  />
                </li>
                <li>
                  <strong>申请费用：</strong>S$ {{ schoolDetails.app_fee }}
                </li>
                <li>
                  <strong>申请要求：</strong>{{ schoolDetails.requirement }}
                </li>
                <li><strong>学校地址：</strong>{{ schoolDetails.address }}</li>
              </ul>
            </div>

            <br />
            <div>
              <ServiceDetailsSidebar
                v-if="schoolSubjects != null"
                :schoolSubjects="schoolSubjects"
              />
            </div>
          </div>

          <div
            class="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15"
          >
            <div class="project-details">
              <!-- <h2>{{ data.projectDetails.projectDetailsTitle }}</h2>
                            <p v-html="data.projectDetails.projectDetailsDesc">{{ data.projectDetails.projectDetailsDesc }}</p> -->
              <div class="content section-space--top--0">
                <div class="row">
                  <div class="col-12">
                    <h2>院校介绍</h2>
                    <div
                      v-for="(intro, index) in schoolDetails.introduction"
                      :key="index"
                    >
                      <h4>{{ intro.title }}</h4>
                      <p>{{ intro.content }}</p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12">
                        <div class="row row-5 image-popup">
                            <div class="col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10" v-for="gallery in data.projectDetailsGallery" :key="gallery.id">
                                <router-link to="/project-details" class="gallery-item single-gallery-thumb">
                                    <img :src="gallery.image" class="img-fluid" alt="thumbnail">
                                    <span class="plus"></span>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
        </div>
        <div>
          <SchoolSubject :schoolSubjects="schoolSubjects" />
        </div>
        <!-- <div>
          <CourseList :schoolSubjects="schoolSubjects" />
        </div> -->
        <div id="contact-us-form">
          <HereMap
            :center="{
              lat: this.schoolDetails.lat,
              lng: this.schoolDetails.lng,
            }"
          />
        </div>

        <div style="padding-top:20px;">
          <ConsultantQR />
        </div>
      </div>
    </div>
    <!--Projects section end-->
    <!--Service section start-->
    <!-- <div class="service-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-12 order-2 order-lg-1">
            <ServiceDetailsMain :schoolDetails="schoolDetails" />
          </div>

          <div class="col-lg-4 col-12 order-1 order-lg-2">
            <ServiceDetailsSidebar :schoolSubjects="schoolSubjects" />
          </div>
        </div>
      </div>
    </div> -->
    <!--Service section end-->
  </div>
</template>

<script>
import ServiceDetailsSidebar from "@/components/ServiceDetailsSidebar";
import ServiceDetailsMain from "@/components/ServiceDetailsMain";
import ConsultantQR from "@/components/ConsultantQR";

import data from "../../data/service.json";
import CourseList from "@/components/CourseList";
import HereMap from '@/components/HereMap'

import SchoolSubject from "@/components/SchoolSubject";
export default {
  props: ["schoolDetails", "schoolSubjects"],
  components: {
    ServiceDetailsSidebar,
    ServiceDetailsMain,
    ConsultantQR,
    CourseList,
    HereMap,
    SchoolSubject,
  },
  data() {
    return {
      data,
    };
  },

  methods: {},
};
</script>

<style lang="scss">
</style>