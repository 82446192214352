<template>
  <div style="padding-bottom: 20px">
    <div>
      <div
        v-for="(type, i) in schoolSubjects"
        :key="i"
        style="padding-bottom: 5px"
      >
        <b-button
          squared
          block
          v-b-toggle="'accordion-' + i"
          variant="info"
          style="height: 50px; font-size: 18px"
          v-if="schoolSubjects[i].subjects.length != 0"
          >{{ type.type }}</b-button
        >

        <b-collapse :id="'accordion-' + i">
          <ul class="sidebar-list">
            <li v-for="(subject, id) in schoolSubjects[i].subjects" :key="id">
              <div class="parent">
                <div>
                  <img
                    class='list_img'
                    v-if="subject.has_cooper_university == false"
                    :src="
                      $baseUrl +
                      subject.school.school_logo
                    "
                  />
                  <img
                  class='list_img'
                    style="width: 100px; height: 62px"
                    v-if="subject.has_cooper_university == true"
                    :src="
                      $baseUrl +
                      subject.cooperative_university.logo
                    "
                  />
                </div>
                <div class="wide" style="padding-top: 15px">
                  <h4>{{ subject.name_chs }}</h4>
                  <p
                    v-if="subject.has_cooper_university == false"
                    style="font-size: 13px"
                  >
                    文凭授予院校: {{ subject.school.name_chs }}
                  </p>
                  <p
                    v-if="subject.has_cooper_university == true"
                    style="font-size: 13px"
                  >
                    文凭授予院校:
                    {{ subject.cooperative_university.name_chs }}
                  </p>
                </div>
                <div class="detail" style="padding-top: 10px">
                  <p style="font-size: 13px">
                    学费: S${{ subject.subject_fee }}
                  </p>

                  <a
                    @click="$bvModal.show('modal-' + i + id)"
                    style="color: #2b63ae"
                    >查看详情</a
                  >

                  <b-modal
                    :id="'modal-' + i + id"
                    hide-footer
                    size="xl"
                    centered
                    static
                    no-stacking
                  >
                    <template #modal-title>
                      <h3>{{ subject.name_chs }}</h3>
                      <p>{{ subject.name_eng }}</p>
                    </template>
                    <div class="d-block">
                      <p>
                        {{ subject.subject_type_main.name }} -
                        {{ subject.subject_type_sub.name }}
                      </p>
                      <p>专业介绍：{{ subject.subject_introduction }}</p>
                      <p>学制：{{ subject.subject_duration }}个月</p>
                      <p>
                        学费：S${{ subject.subject_fee }} - 约{{
                          subject.subject_fee * 5
                        }}人民币
                      </p>
                      <p>开课时间：{{ subject.subject_start_date }}</p>
                      <p>申请截至时间：{{ subject.apply_deadline }}</p>
                      <p>申请条件：{{ subject.apply_requirement }}</p>
                      <hr />
                      <div v-if="subject.has_cooper_university == true">
                        <h4>合作大学</h4>
                        <img
                          :src="
                            $baseUrl +
                            subject.cooperative_university.logo
                          "
                        />
                        <p>{{ subject.cooperative_university.name_chs }}</p>
                        <p>{{ subject.cooperative_university.name_eng }}</p>
                        <p>
                          世界大学排名：
                          {{ subject.cooperative_university.rank }}
                        </p>
                        <p>
                          学校介绍：
                          {{ subject.cooperative_university.introduction }}
                        </p>
                      </div>
                    </div>
                    <b-button
                      class="mt-3"
                      variant="success"
                      block
                      @click="clickGoContact(i, id)"
                      >马上申请</b-button
                    >
                    <b-button
                      class="mt-3"
                      style="background-color: #2b63ae"
                      block
                      @click="$bvModal.hide('modal-' + i + id)"
                      >确定</b-button
                    >
                  </b-modal>
                  <b-modal
                    id="modal-contact"
                    hide-footer
                    hide-header
                    hide
                    size="sm"
                    centered
                    static
                  >
                    <div class="d-block">
                      <p>
                        {{ subject.subject_type_main.name }} -
                        {{ subject.subject_type_sub.name }}
                      </p>
                    </div>
                    <b-button
                      class="mt-3"
                      variant="success"
                      block
                      @click="$bvModal.hide('modal-contact')"
                      >马上申请</b-button
                    >
                    <b-button
                      class="mt-3"
                      style="background-color: #2b63ae"
                      block
                      @click="$bvModal.hide('modal-contact')"
                      >确定</b-button
                    >
                  </b-modal>
                </div>
              </div>
            </li>
          </ul>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["schoolSubjects"],
  data() {
    return {
      modalShow: false,
    };
  },
  created() {
    this.$nextTick(() => {
      console.log(this.schoolSubjects);
    });
  },
  methods: {
    clickGoContact(i, id) {
      this.$bvModal.hide("modal-" + i + id);
      document.getElementById("contact-us-form").scrollIntoView();
    },
  },
};
</script>
<style lang="scss">
.parent {
  display: flex;
}
.list_img {
  flex: 0;
  width: 100px;
  height: 62px;
  padding: 5px;
}
.wide {
  flex: 1;
  padding-left: 10px;
  width: 340px;
}
.detail {
  order: 2;
  width: 110px;
}
.maxp{
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  overflow: hidden;
  max-width: 50%; /* (Number of lines you want visible) * (line-height) */
  line-height: 1.2em;
  max-height: 1.2em;
  text-align:justify;
}
</style>